/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    smooth_scroll: function(){
      $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
          var target = $(this.hash);
          var topBar = $('#Top_bar').height();
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
            $('html, body').animate({
              scrollTop: target.offset().top - topBar
            }, 250);
            return false;
          }
        }
      });
    },
    pick_a_category: function(menuClass){
      $('.pick-a-category').on('click',function(){
         $(menuClass).toggle();
      });
      $(menuClass+' '+'li > a').on('click',function(){
         $(menuClass).toggle();
      });
    },
    sticky_buttons: function(){
       if ($('#Top_bar').hasClass('is-sticky')){
          $('.header-buttons .btn').addClass('btn-sticky');
        } else{
          $('.header-buttons .btn').removeClass('btn-sticky');
        }
    },
    our_menus_sticky: function(){
      var main_header = $('#Top_bar');
      var sub_header = $('#Subheader');
      var our_menu = $('#is-sticky--our-menus');
       document.onscroll = function() {
          if( $(window).scrollTop() > sub_header.height() ) {
            if (main_header.hasClass('is-sticky') ){
              if ($(window).width() > 1240) {
                 our_menu.addClass('is-sticky').css({
                    'top': main_header.height()
                  });
              } else{
                our_menu.addClass('is-sticky').css({
                    'top': '0'
                  });
              }
            }
            else if (!main_header.hasClass('is-sticky') ){
              our_menu.removeClass('is-sticky').css({
                'top': 'auto'
              });
            }
          }
          else {
            our_menu.removeClass('is-sticky').css({
                'top': 'auto'
              });
          }
        };
    },
    // All pages
    'common': {
      init: function() {
        Sage.pick_a_category();
        $(window).scroll(function(){
          Sage.sticky_buttons();
        });
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'our_menus':{
      init: function(){
        Sage.smooth_scroll();
        Sage.pick_a_category('.menu-daily-food-menu-categories-container');
        Sage.our_menus_sticky();
      }
    },
    'catering_menu':{
      init: function(){
        Sage.smooth_scroll();
        Sage.pick_a_category('.menu-catering-menu-categories-container');
        Sage.our_menus_sticky();
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
